import { useQuery } from 'react-query'
import { queries } from '../constants/queries'
import { useOwnedNfts } from './queries/useOwnedNfts'
import { NftModel, UtilityModel } from '@fuel/nft'

export const useOwnedCollections = () => {
  const { getOwnedNfts } = useOwnedNfts()

  return useQuery<{ nfts: NftModel[]; notifications: UtilityModel[] }>(
    queries.OWNED_NFTS,
    async () => {
      return await getOwnedNfts()
    },
    {
      refetchOnMount: true,
      retry: 3,
    }
  )
}
