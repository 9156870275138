import React, { FC } from 'react'
import { Alert, AlertIcon, Box, Flex, Link } from '@chakra-ui/react'
import { UtilityModel } from '@fuel/nft'
import { useTranslation } from 'next-i18next'
import { isBefore } from 'date-fns'

type Props = {
  notifications?: UtilityModel[]
  onNotificationClose: (notifications: UtilityModel) => void
}

export const Notifications: FC<Props> = ({ notifications, onNotificationClose }) => {
  const { t } = useTranslation('common')
  if (!notifications?.length) {
    return null
  }

  return (
    <div>
      {notifications.map(notification => {
        const currentDate = new Date(notification.date)

        if (isBefore(currentDate, new Date())) {
          return null
        }

        return (
          <Alert
            status="warning"
            mt="20px"
            py="20px"
            display="flex"
            justifyContent="space-between"
            borderRadius="17px"
            key={notification.name}
            bg="#FFDCCD80"
          >
            <Flex>
              <AlertIcon color="#FF996D" />
              <Box>
                <Box as="span" mr="5px">
                  {notification.notification}
                </Box>
                <Link color="#1A1347" fontWeight="600" onClick={() => onNotificationClose(notification)} as="button">
                  {t('qr_code_link')}
                </Link>
              </Box>
            </Flex>
          </Alert>
        )
      })}
    </div>
  )
}
