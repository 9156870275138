import { useAxiosWithAuth } from '@fuel/api'
import { useRouter } from 'next/router'
import { api } from '../../constants/api'

export const useOwnedNfts = () => {
  const axiosApi = useAxiosWithAuth()
  const router = useRouter()
  const getOwnedNfts = async () => {
    try {
      const { data } = await axiosApi.get(`${api.GET_OWNED_NFTS}`, {
        withCredentials: false,
      })

      return data
    } catch (error) {
      console.error(error)
      return null
    }
  }

  return { getOwnedNfts }
}
