import React, { useEffect, useState } from 'react'
import { NftList } from '@fuel/ui'
import { DashboardLayout } from '@fuel/core'
import { useOwnedCollections } from '@fuel/wallet-core'
import { routes } from '../constants/routes'
import { walletNavigationItems } from '../constants/walletNavigationItems'
import { Flex, SimpleGrid, Skeleton, Text, useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { Notifications } from '../components/notifications/Notifications'
import { NotificationModal } from '../components/notifications/NotificationModal'
import { UtilityModel } from '@fuel/nft'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'

const Wallet = () => {
  const { t } = useTranslation('common')
  const { data, isFetching, isFetched } = useOwnedCollections()
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentUtility, setUtility] = useState<UtilityModel>()

  useEffect(() => {
    const authTokens = localStorage.getItem('authTokens')
    if (!authTokens) {
      router.push('/login')
    }
  }, [])

  const acceptNotification = () => {
    window.open(currentUtility.url, currentUtility.type, 'noopener,noreferrer')
  }

  const onNotificationClose = (utility: UtilityModel) => {
    setUtility(utility)
    onOpen()
  }

  return (
    <>
      <NotificationModal isOpen={isOpen} onClose={onClose} onConfirm={acceptNotification} />
      <DashboardLayout
        logoRoute={routes.HOME}
        title={t('my_nfts')}
        navigationItems={walletNavigationItems}
        redirectPathOnLogout="/login"
        notifications={<Notifications notifications={data?.notifications} onNotificationClose={onNotificationClose} />}
      >
        <>
          {isFetching ? (
            <Flex display={['block', 'block', 'flex']} gap="10px">
              <Skeleton height="325px" w="full" mb="10px" />
              <Skeleton height="325px" w="full" mb="10px" />
              <Skeleton height="325px" w="full" mb="10px" />
            </Flex>
          ) : isFetched && data?.nfts?.length > 0 ? (
            <SimpleGrid columns={[1, 1, 2, 3]} spacing="25px" pb="80px">
              <NftList nfts={data.nfts} isFetching={isFetching} onNotificationClose={onNotificationClose} />
            </SimpleGrid>
          ) : (
            <Text>{t('no_nfts')}</Text>
          )}
        </>
      </DashboardLayout>
    </>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default Wallet
