import { NavigationItemModel } from '../model/NavigationItemModel'
import { routes } from './routes'

export const walletNavigationItems: NavigationItemModel[] = [
  {
    translation_key: 'wallet',
    name: 'Wallet',
    icon: 'wallet',
    url: routes.HOME,
  },
]
