import React, { FC } from 'react'
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const NotificationModal: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation('common')

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent borderRadius="17px" fontFamily="Inter">
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <Text pt="70px" fontSize="28px" fontWeight="800">
            {t('qr_code_entry_title')}
          </Text>
          <Box maxW="440px" mx="auto" fontSize="14px">
            <Text mt="16px">{t('qr_code_entry_description_1')}</Text>
            <Text mt="16px">{t('qr_code_entry_description_2')}</Text>
            <Flex mt="32px" flexDirection="column" textAlign="left" maxW="340px" mx="auto">
              <Text>{t('qr_code_entry_step_1')}</Text>
              <Text>{t('qr_code_entry_step_2')}</Text>
              <Text>{t('qr_code_entry_step_3')}</Text>
              <Text>{t('qr_code_entry_step_4')}</Text>
              <Text>{t('qr_code_entry_step_5')}</Text>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex
            justifyContent={['center', 'center', 'space-between']}
            flexDirection={['column-reverse', 'column-reverse', 'row']}
            w="100%"
            maxW="500px"
            mx="auto"
            mb="70px"
            mt="40px"
            flexWrap="wrap"
          >
            <Button onClick={onClose} variant="ghost" color="#907DFE" borderRadius="12px">
              {t('later_complete')}
            </Button>
            <Button onClick={onConfirm} bg="#ADFFB5" borderRadius="12px">
              {t('visit_tokenproof')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
